<div class="modal-dialog">
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title">{{data.title}}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()"></button>
        </div>

        <div class="modal-body text-center m-4">
            <div class="p-4 border rounded">
                <img [src]="data.image" alt="Firma" style="max-width: 100%;">
            </div>
        </div>

        <div class="modal-footer">
            <button mat-button (click)="closeModal()" class="btn btn-secondary me-2">{{dataMsg.BUTTON_BACK}}</button>
            <button mat-button (click)="confirmModal()" class="btn btn-primary" cdkFocusInitial>{{data.button_title}}</button>
        </div>
    </div>    
</div>