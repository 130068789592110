export const ROUTES_PATHS = {
    AUTH: {
        DEFAULT: 'auth',
        LOGIN: 'login',
        FORGOT_PASSWORD: 'forgot-password',
        USER_COMPANY: 'company',
    },
    PANEL: {
        DEFAULT: '',
        USER: 'user',
        USER_LIST: 'list',
        USER_NEW: 'new',
        EDIT: 'edit',
        EDIT_ID:  'edit/:id',
        USER_UNAUTHORIZED: 'unauthorized',
        USER_ROLES_LIST: 'user-roles',
        USER_ROLES_ASSIGN: 'user-roles-assign',
    },
    USER_GENERAL: {
        DEFAULT: '',
        HOME: 'user-general',
        USER_UPDATE_PASSWORD: 'update-password',
        PROFILE: 'user-profile',
    },
    KATIA_MATERIAL: {
        DEFAULT: '',
        HOME: 'material',
        MATERIAL_LIST: 'material-list',
        MATERIAL_NEW: 'material-new',
        MATERIAL_EDIT: 'material-edit',
        MATERIAL_EDIT_ID: 'material-edit/:id',
        MATERIAL_HISTORICAL: 'material-historical',
    },
    KATIA_PROVIDER: {
        DEFAULT: '',
        HOME: 'proveedor',
        PROVIDER_LIST: 'proveedor-list',
        PROVIDER_NEW: 'proveedor-new',
        PROVIDER_EDIT: 'proveedor-edit',
        PROVIDER_EDIT_ID: 'proveedor-edit/:id',
        PROVIDER_MATERIALES_EDIT: 'proveedor-materiales',
        PROVIDER_MATERIALES_EDIT_ID: 'proveedor-materiales/:id',
    },
    KATIA_ORDEN_COMPRA: {
        DEFAULT: '',
        HOME: 'orden-compra',
        ORDEN_COMPRA_LIST: 'ordencompra-list',
        ORDEN_COMPRA_NEW: 'ordencompra-new',
        ORDEN_COMPRA_ENTER_ORDER: 'ordencompra-enter-order',
        ORDEN_COMPRA_ENTER_ORDER_ID: 'ordencompra-enter-order/:id',
        ORDEN_COMPRA_CUARENTENA: 'ordencompra-cuarentena',
        ORDEN_COMPRA_CUARENTENA_ID: 'ordencompra-cuarentena/:id',
        ORDEN_COMPRA_CUARENTENA_EDIT: 'ordencompra-cuarentena-edit',
        ORDEN_COMPRA_CUARENTENA_EDIT_ID: 'ordencompra-cuarentena-edit/:id',
        ORDEN_COMPRA_LIST_MATERIALS: 'ordencompra-list-materials',
        ORDEN_COMPRA_LIST_MATERIALS_ID: 'ordencompra-list-materials/:id',
        ORDEN_COMPRA_LIST_EDIT: 'ordencompra-list-edit',  // Agrega la nueva ruta aquí
        ORDEN_COMPRA_LIST_EDIT_ID: 'ordencompra-list-edit/:id',  // Agrega la nueva ruta aquí
        ORDEN_COMPRA_EDITAR_PDF_ID: 'editar-pdf/:id',
        ORDEN_COMPRA_UNITARIO_EDIT_ID: 'ordencompra-unitario-edit/:id',
        ORDEN_COMPRA_UNITARIO_EDIT: 'ordencompra-unitario-edit',
        ORDEN_COMPRA_UNITARIO: 'ordencompra-unitario',
        ORDEN_COMPRA_UNITARIO_ID: 'ordencompra-unitario/:id',
        ORDEN_COMPRA_NOTA_CREDITO: 'ordencompra-nota-credito',
        ORDEN_COMPRA_NOTA_CREDITO_ID: 'ordencompra-nota-credito/:id',


    },
    COMPANY: {
        DEFAULT: '',
        COMPANY: 'company',
        LIST: 'list',
        NEW: 'new',
        EDIT: 'edit',
        EDIT_ID:  'edit/:id',
        ASSIGN_MODULE: 'assign-module',
        LIST_USERS: 'list-users',
        LIST_USERS_ID: 'list-users/:id',
    },
    MODULE: {
        DEFAULT: '',
        MODULE: 'module',
        LIST: 'list',
        NEW: 'new',
        EDIT: 'edit',
        EDIT_ID:  'edit/:id',
        SUBMODULE: 'submodule',
        SUBMODULE_ID: 'submodule/:id'
    },
    SUBMODULE: {
        DEFAULT: '',
        SUBMODULE: 'submodule',
        LIST: 'list/:id',
    },
    ROLE: {
        DEFAULT: '',
        ROLE: 'role',
        LIST: 'list',
        NEW: 'new',
        EDIT: 'edit',
        EDIT_ID:  'edit/:id',
        SUBMODULE_ASSIGN : 'submodule-assign',
        SUBMODULE_ASSIGN_ID : 'submodule-assign/:id',
        PERMISSIONS_ROLE : 'permissions-role',
        PERMISSIONS_ROLE_ID : 'permissions-role/:id'
    },
    SUPERADMIN: {
        DEFAULT: '',
        SUPERADMIN: 'superadmin',
        USERS: 'users',
        MODULES: 'modules',
    },
    KATIA_QUALITY: {
        DEFAULT: '',
        HOME: 'calidad',
        ALL_MATERIALS_LIST: 'calidad-all-materials-list',
        MATERIALS_TO_CHECK_LIST: 'calidad-materials-to-check-list',
        MATERIAL_VIEW: 'calidad-material-view',
        MATERIAL_VIEW_ID: 'calidad-material-view/:id',
        INSPECTION_ALL_MATERIALS_LIST: 'calidad-inspection-all-material-list',
        INSPECTION_PROD_MEE_NEW: 'calidad-inspection-prodmee-new',
        INSPECTION_PROD_MEE_NEW_ID: 'calidad-inspection-prodmee-new/:id',
        INSPECTION_PROD_MEE_EDIT: 'calidad-inspection-prodmee-edit',
        INSPECTION_PROD_MEE_EDIT_ID: 'calidad-inspection-prodmee-edit/:id',
        INSPECTION_PROD_MP_NEW: 'calidad-inspection-prodmp-new',
        INSPECTION_PROD_MP_NEW_ID: 'calidad-inspection-prodmp-new/:id',
        INSPECTION_PROD_MP_EDIT: 'calidad-inspection-prodmp-edit',
        INSPECTION_PROD_MP_EDIT_ID: 'calidad-inspection-prodmp-edit/:id',
        LIBERACION_AGUA: 'calidad-liberacion-agua-list',
        LIBERACION_AGUA_NEW: 'calidad-liberacion-agua-new',
        LIBERACION_AGUA_EDIT: 'liberacion-agua-edit',
        LIBERACION_AGUA_EDIT_ID: 'liberacion-agua-edit/:id'
    },
    KATIA_PRODUCTION: {
        DEFAULT: '',
        HOME: 'produccion',
        LIST_ALL_PRODUCT_SHEETS:'produccion-list-all-product-sheets',
        NEW: 'new',
        LIST_ALL_ORDER:'produccion-list-all-order',
        NEW_ORDER: 'new-order',
        PRODUCT_VIEW: 'produccion-product-view',
        PRODUCT_VIEW_ID: 'produccion-product-view/:id',
        PRODUCT_EDIT: 'produccion-product-edit',
        PRODUCT_DUPLICATE_ID: 'produccion-product-duplicate/:id',
        PRODUCT_DUPLICATE: 'produccion-product-duplicate',
        PRODUCT_EDIT_ID: 'produccion-product-edit/:id',
        ORDER_VIEW: 'produccion-order-view',
        ORDER_VIEW_ID: 'produccion-order-view/:id',
        ORDER_EDIT: 'produccion-order-edit',
        ORDER_EDIT_ID: 'produccion-order-edit/:id',
        INSTRUCTIVO_CONFIG: 'produccion-instructivo-config',
        INSTRUCTIVO_CONFIG_ID: 'produccion-instructivo-config/:id',
        ORDER_INSTRUCTIVO: 'produccion-order-instructivo',
        ORDER_INSTRUCTIVO_ID: 'produccion-order-instructivo/:id/:producto',
    },
    KATIA_BODEGA: {
        DEFAULT: '',
        HOME: 'bodega',
        MATERIALES_LIST: 'bodega-materiales-list',
        MATERIALES_NEW: 'bodega-materiales-new',
        PRODUCTOS_LIST: 'bodega-productos-list',
        PRODUCTOS_NEW: 'bodega-productos-new',
        EXISTENCIAS_MATERIALES_LIST: 'bodega-existencias-materiales-list',
        EXISTENCIAS_PRODUCTOS_LIST: 'bodega-existencias-productos-list',

    },
    KATIA_COMERCIAL: {
        DEFAULT: '',
        HOME: 'comercial',
        PROVEEDOR_INT_LIST: 'proveedor-int-list',
        PROVEEDOR_INT_NEW: 'proveedor-int-new',
        PROVEEDOR_INT_EDIT: 'proveedor-int-edit',
        CLIENTE_INT_LIST: 'cliente-int-list',

        CLIENTE_INT_NEW: 'cliente-int-new',
        CLIENTE_INT_EDIT: 'cliente-int-edit',
        PROFORMA_EDIT: 'proforma',
        PROFORMA_LIST: 'proforma-list',
        PROFORMA_NEW: 'proforma-new',
        EMPAQUES_EDIT: 'empaques',
        EMPAQUES_LIST: 'empaques-list',
        EMPAQUES_NEW: 'empaques-new',

        SEGUIMIENTO_LIST: 'seguimiento',
        SEGUIMIENTO_NEW: 'seguimiento-new',
    },
    KATIA_EQUIPO: {
      DEFAULT: '',
      HOME: 'equipos',
      EQUIPO_LIST: 'equipos-list',
      EQUIPO_NEW: 'equipos-new',
      EQUIPO_EDIT: 'equipos-edit',
      EQUIPO_EDIT_ID: 'equipos-edit/:id',
      MANTENIMIENTO_EQUIPO_LIST: 'mantenimiento-equipo-list',
      MANTENIMIENTO_EQUIPO_NEW: 'mantenimiento-equipo-new',
      MANTENIMIENTO_EQUIPO_EDIT: 'mantenimiento-equipo-edit',
      MANTENIMIENTO_EQUIPO_EDIT_ID: 'mantenimiento-equipo-edit/:id',
    },
    KATIA_PROGRAMACION:{
      DEFAULT: '',
      HOME: 'programacion',
      CRONOGRAMA_LIST: 'cronograma-list',
      CRONOGRAMA_DETALLE_LIST: 'cronograma-detalle-list',
      CRONOGRAMA_DETALLE_LIST_DATA: 'cronograma-detalle-list/:id',
      CRONOGRAMA_NEW: 'cronograma-new',
      CRONOGRAMA_EDIT: 'cronograma-edit',
      CRONOGRAMA_EDIT_ID: 'cronograma-edit/:id',
      PLANEACION_LIST: 'planeacion-list',
      PLANEACION_NEW: 'planeacion-new',
      PLANEACION_EDIT: 'planeacion-edit',
      PLANEACION_EDIT_ID: 'planeacion-edit/:id',

    },
    SERVER: {
        ERROR_401: '401',
        ERROR_403: '403',
        ERROR_404: '404',
    }
};

export const INTERNAL_PATHS = {
    /**
     * AUTHENTICATION PATHS
     */
    AUTH_DEFAULT: `${ROUTES_PATHS.AUTH.DEFAULT}`,
    AUTH_LOGIN: `${ROUTES_PATHS.AUTH.LOGIN}`,
    AUTH_FORGOT_PASSWORD: `${ROUTES_PATHS.AUTH.FORGOT_PASSWORD}`,
    AUTH_USER_COMPANY: `${ROUTES_PATHS.AUTH.USER_COMPANY}`,

    /**
     * PANEL USER PATHS
     */
    PANEL_DEFAULT: `${ROUTES_PATHS.PANEL.DEFAULT}`,
    PANEL_USER_HOME: `${ROUTES_PATHS.PANEL.USER}`,
    PANEL_USER_LIST: `${ROUTES_PATHS.PANEL.USER_LIST}`,
    PANEL_USER_NEW: `${ROUTES_PATHS.PANEL.USER_NEW}`,
    PANEL_USER_EDIT: `${ROUTES_PATHS.PANEL.EDIT}`,
    PANEL_USER_EDIT_ID: `${ROUTES_PATHS.PANEL.EDIT_ID}`,
    PANEL_USER_UNAUTHORIZED: `${ROUTES_PATHS.PANEL.USER_UNAUTHORIZED}`,
    PANEL_USER_ROLES_LIST: `${ROUTES_PATHS.PANEL.USER_ROLES_LIST}`,
    PANEL_USER_ROLE_ASSIGN: `${ROUTES_PATHS.PANEL.USER_ROLES_ASSIGN}`,


    /**
     * PANEL USER PATHS
     */
    USER_GENERAL_DEFAULT: `${ROUTES_PATHS.USER_GENERAL.DEFAULT}`,
    USER_GENERAL_HOME: `${ROUTES_PATHS.USER_GENERAL.HOME}`,
    USER_GENERAL_UPDATE_PASSWORD: `${ROUTES_PATHS.USER_GENERAL.USER_UPDATE_PASSWORD}`,
    USER_GENERAL_PROFILE: `${ROUTES_PATHS.USER_GENERAL.PROFILE}`,

    /**
     * PANEL KATIA MATERIAL
     */
    MATERIAL_DEFAULT: `${ROUTES_PATHS.KATIA_MATERIAL.DEFAULT}`,
    MATERIAL_HOME: `${ROUTES_PATHS.KATIA_MATERIAL.HOME}`,
    MATERIAL_LIST: `${ROUTES_PATHS.KATIA_MATERIAL.MATERIAL_LIST}`,
    MATERIAL_NEW: `${ROUTES_PATHS.KATIA_MATERIAL.MATERIAL_NEW}`,
    MATERIAL_EDIT: `${ROUTES_PATHS.KATIA_MATERIAL.MATERIAL_EDIT}`,
    MATERIAL_EDIT_ID: `${ROUTES_PATHS.KATIA_MATERIAL.MATERIAL_EDIT_ID}`,
    MATERIAL_HISTORICAL: `${ROUTES_PATHS.KATIA_MATERIAL.MATERIAL_HISTORICAL}`,

    /**
     * PANEL KATIA PROVIDER
     */
    PROVIDER_DEFAULT: `${ROUTES_PATHS.KATIA_PROVIDER.DEFAULT}`,
    PROVIDER_HOME: `${ROUTES_PATHS.KATIA_PROVIDER.HOME}`,
    PROVIDER_LIST: `${ROUTES_PATHS.KATIA_PROVIDER.PROVIDER_LIST}`,
    PROVIDER_NEW: `${ROUTES_PATHS.KATIA_PROVIDER.PROVIDER_NEW}`,
    PROVIDER_EDIT: `${ROUTES_PATHS.KATIA_PROVIDER.PROVIDER_EDIT}`,
    PROVIDER_EDIT_ID: `${ROUTES_PATHS.KATIA_PROVIDER.PROVIDER_EDIT_ID}`,
    PROVIDER_MATERIALES_EDIT_ID: `${ROUTES_PATHS.KATIA_PROVIDER.PROVIDER_MATERIALES_EDIT_ID}`,
    PROVIDER_MATERIALES_EDIT: `${ROUTES_PATHS.KATIA_PROVIDER.PROVIDER_MATERIALES_EDIT}`,

    /**
     * PANEL KATIA ORDEN COMPRA
     */
    ORDEN_COMPRA_DEFAULT: `${ROUTES_PATHS.KATIA_ORDEN_COMPRA.DEFAULT}`,
    ORDEN_COMPRA_HOME: `${ROUTES_PATHS.KATIA_ORDEN_COMPRA.HOME}`,
    ORDEN_COMPRA_LIST: `${ROUTES_PATHS.KATIA_ORDEN_COMPRA.ORDEN_COMPRA_LIST}`,
    ORDEN_COMPRA_NEW: `${ROUTES_PATHS.KATIA_ORDEN_COMPRA.ORDEN_COMPRA_NEW}`,
    ORDEN_COMPRA_ENTER_ORDER: `${ROUTES_PATHS.KATIA_ORDEN_COMPRA.ORDEN_COMPRA_ENTER_ORDER}`,
    ORDEN_COMPRA_ENTER_ORDER_ID: `${ROUTES_PATHS.KATIA_ORDEN_COMPRA.ORDEN_COMPRA_ENTER_ORDER_ID}`,
    ORDEN_COMPRA_CUARENTENA: `${ROUTES_PATHS.KATIA_ORDEN_COMPRA.ORDEN_COMPRA_CUARENTENA}`,
    ORDEN_COMPRA_CUARENTENA_ID: `${ROUTES_PATHS.KATIA_ORDEN_COMPRA.ORDEN_COMPRA_CUARENTENA_ID}`,
    ORDEN_COMPRA_CUARENTENA_EDIT: `${ROUTES_PATHS.KATIA_ORDEN_COMPRA.ORDEN_COMPRA_CUARENTENA_EDIT}`,
    ORDEN_COMPRA_CUARENTENA_EDIT_ID: `${ROUTES_PATHS.KATIA_ORDEN_COMPRA.ORDEN_COMPRA_CUARENTENA_EDIT_ID}`,
    ORDEN_COMPRA_LIST_MATERIALS: `${ROUTES_PATHS.KATIA_ORDEN_COMPRA.ORDEN_COMPRA_LIST_MATERIALS}`,
    ORDEN_COMPRA_LIST_MATERIALS_ID: `${ROUTES_PATHS.KATIA_ORDEN_COMPRA.ORDEN_COMPRA_LIST_MATERIALS_ID}`,
    ORDEN_COMPRA_LIST_EDIT: `${ROUTES_PATHS.KATIA_ORDEN_COMPRA.ORDEN_COMPRA_LIST_EDIT}`,
    ORDEN_COMPRA_LIST_EDIT_ID: `${ROUTES_PATHS.KATIA_ORDEN_COMPRA.ORDEN_COMPRA_LIST_EDIT_ID}`,
    ORDEN_COMPRA_EDITAR_PDF_ID:`${ROUTES_PATHS.KATIA_ORDEN_COMPRA.ORDEN_COMPRA_EDITAR_PDF_ID}`,
    ORDEN_COMPRA_UNITARIO_EDIT_ID:`${ROUTES_PATHS.KATIA_ORDEN_COMPRA.ORDEN_COMPRA_UNITARIO_EDIT_ID}`,
    ORDEN_COMPRA_UNITARIO_EDIT:`${ROUTES_PATHS.KATIA_ORDEN_COMPRA.ORDEN_COMPRA_UNITARIO_EDIT}`,
    ORDEN_COMPRA_UNITARIO:`${ROUTES_PATHS.KATIA_ORDEN_COMPRA.ORDEN_COMPRA_UNITARIO}`,
    ORDEN_COMPRA_UNITARIO_ID:`${ROUTES_PATHS.KATIA_ORDEN_COMPRA.ORDEN_COMPRA_UNITARIO_ID}`,
    ORDEN_COMPRA_NOTA_CREDITO:`${ROUTES_PATHS.KATIA_ORDEN_COMPRA.ORDEN_COMPRA_NOTA_CREDITO}`,
    ORDEN_COMPRA_NOTA_CREDITO_ID:`${ROUTES_PATHS.KATIA_ORDEN_COMPRA.ORDEN_COMPRA_NOTA_CREDITO_ID}`,


    /**
     * COMPANY PATHS
     */
    COMPANY_DEFAULT: `${ROUTES_PATHS.COMPANY.DEFAULT}`,
    COMPANY_HOME: `${ROUTES_PATHS.COMPANY.COMPANY}`,
    COMPANY_LIST: `${ROUTES_PATHS.COMPANY.LIST}`,
    COMPANY_NEW: `${ROUTES_PATHS.COMPANY.NEW}`,
    COMPANY_EDIT: `${ROUTES_PATHS.COMPANY.EDIT}`,
    COMPANY_EDIT_ID: `${ROUTES_PATHS.COMPANY.EDIT_ID}`,
    COMPANY_ASSIGN_MODULE: `${ROUTES_PATHS.COMPANY.ASSIGN_MODULE}`,
    COMPANY_LIST_USERS: `${ROUTES_PATHS.COMPANY.LIST_USERS}`,
    COMPANY_LIST_USERS_ID: `${ROUTES_PATHS.COMPANY.LIST_USERS_ID}`,

    /**
     * MODULE PATHS
     */
    MODULE_DEFAULT: `${ROUTES_PATHS.MODULE.DEFAULT}`,
    MODULE_HOME: `${ROUTES_PATHS.MODULE.MODULE}`,
    MODULE_LIST: `${ROUTES_PATHS.MODULE.LIST}`,
    MODULE_NEW: `${ROUTES_PATHS.MODULE.NEW}`,
    MODULE_EDIT: `${ROUTES_PATHS.MODULE.EDIT}`,
    MODULE_EDIT_ID: `${ROUTES_PATHS.MODULE.EDIT_ID}`,
    MODULE_SUBMODULE: `${ROUTES_PATHS.MODULE.SUBMODULE}`,
    MODULE_SUBMODULE_LIST_ID: `${ROUTES_PATHS.MODULE.SUBMODULE_ID}`,

    /**
     * MODULE PATHS
     */
    SUBMODULE_DEFAULT: `${ROUTES_PATHS.SUBMODULE.DEFAULT}`,
    SUBMODULE_HOME: `${ROUTES_PATHS.SUBMODULE.SUBMODULE}`,
    SUBMODULE_LIST: `${ROUTES_PATHS.SUBMODULE.LIST}`,

     /**
     * ROLE PATHS
     */
    ROLE_DEFAULT: `${ROUTES_PATHS.ROLE.DEFAULT}`,
    ROLE_HOME: `${ROUTES_PATHS.ROLE.ROLE}`,
    ROLE_LIST: `${ROUTES_PATHS.ROLE.LIST}`,
    ROLE_NEW: `${ROUTES_PATHS.ROLE.NEW}`,
    ROLE_EDIT: `${ROUTES_PATHS.ROLE.EDIT}`,
    ROLE_EDIT_ID: `${ROUTES_PATHS.ROLE.EDIT_ID}`,
    ROLE_SUBMODULE_ASSIGN: `${ROUTES_PATHS.ROLE.SUBMODULE_ASSIGN}`,
    ROLE_SUBMODULE_ASSIGN_ID: `${ROUTES_PATHS.ROLE.SUBMODULE_ASSIGN_ID}`,
    PERMISSIONS_ROLE: `${ROUTES_PATHS.ROLE.PERMISSIONS_ROLE}`,
    PERMISSIONS_ROLE_ID: `${ROUTES_PATHS.ROLE.PERMISSIONS_ROLE_ID}`,

     /**
     * SUPERADMIN PATHS
     */
    SUPERADMIN_DEFAULT: `${ROUTES_PATHS.SUPERADMIN.DEFAULT}`,
    SUPERADMIN_HOME: `${ROUTES_PATHS.SUPERADMIN.SUPERADMIN}`,
    SUPERADMIN_USERS: `${ROUTES_PATHS.SUPERADMIN.USERS}`,
    SUPERADMIN_MODULES: `${ROUTES_PATHS.SUPERADMIN.MODULES}`,

     /**
     * QUALITY PATHS
     */
    QUALITY_DEFAULT: `${ROUTES_PATHS.KATIA_QUALITY.DEFAULT}`,
    QUALITY_HOME: `${ROUTES_PATHS.KATIA_QUALITY.HOME}`,
    QUALITY_ALL_MATERIALS_LIST: `${ROUTES_PATHS.KATIA_QUALITY.ALL_MATERIALS_LIST}`,
    QUALITY_MATERIALS_TO_CHECK_LIST: `${ROUTES_PATHS.KATIA_QUALITY.MATERIALS_TO_CHECK_LIST}`,
    QUALITY_MATERIAL_VIEW: `${ROUTES_PATHS.KATIA_QUALITY.MATERIAL_VIEW}`,
    QUALITY_MATERIAL_VIEW_ID: `${ROUTES_PATHS.KATIA_QUALITY.MATERIAL_VIEW_ID}`,
    QUALITY_INSPECTION_ALL_MATERIALS_LIST: `${ROUTES_PATHS.KATIA_QUALITY.INSPECTION_ALL_MATERIALS_LIST}`,
    QUALITY_INSPECTION_PROD_MEE_NEW: `${ROUTES_PATHS.KATIA_QUALITY.INSPECTION_PROD_MEE_NEW}`,
    QUALITY_INSPECTION_PROD_MEE_NEW_ID: `${ROUTES_PATHS.KATIA_QUALITY.INSPECTION_PROD_MEE_NEW_ID}`,
    QUALITY_INSPECTION_PROD_MEE_EDIT: `${ROUTES_PATHS.KATIA_QUALITY.INSPECTION_PROD_MEE_EDIT}`,
    QUALITY_INSPECTION_PROD_MEE_EDIT_ID: `${ROUTES_PATHS.KATIA_QUALITY.INSPECTION_PROD_MEE_EDIT_ID}`,
    QUALITY_INSPECTION_PROD_MP_NEW: `${ROUTES_PATHS.KATIA_QUALITY.INSPECTION_PROD_MP_NEW}`,
    QUALITY_INSPECTION_PROD_MP_NEW_ID: `${ROUTES_PATHS.KATIA_QUALITY.INSPECTION_PROD_MP_NEW_ID}`,
    QUALITY_INSPECTION_PROD_MP_EDIT: `${ROUTES_PATHS.KATIA_QUALITY.INSPECTION_PROD_MP_EDIT}`,
    QUALITY_INSPECTION_PROD_MP_EDIT_ID: `${ROUTES_PATHS.KATIA_QUALITY.INSPECTION_PROD_MP_EDIT_ID}`,
    QUALITY_LIBERACION_AGUA_LIST: `${ROUTES_PATHS.KATIA_QUALITY.LIBERACION_AGUA}`,
    QUALITY_LIBERACION_AGUA_NEW: `${ROUTES_PATHS.KATIA_QUALITY.LIBERACION_AGUA_NEW}`,
    QUALITY_LIBERACION_AGUA_EDIT: `${ROUTES_PATHS.KATIA_QUALITY.LIBERACION_AGUA_EDIT}`,
    QUALITY_LIBERACION_AGUA_EDIT_ID: `${ROUTES_PATHS.KATIA_QUALITY.LIBERACION_AGUA_EDIT_ID}`,
     /**
     * QUALITY PATHS
     */
    PRODUCTION_DEFAULT: `${ROUTES_PATHS.KATIA_PRODUCTION.DEFAULT}`,
    PRODUCTION_HOME: `${ROUTES_PATHS.KATIA_PRODUCTION.HOME}`,
    PRODUCTION_LIST_ALL_PRODUCT_SHEETS: `${ROUTES_PATHS.KATIA_PRODUCTION.LIST_ALL_PRODUCT_SHEETS}`,
    PRODUCTION_NEW: `${ROUTES_PATHS.KATIA_PRODUCTION.NEW}`,
    PRODUCTION_LIST_ALL_ORDER: `${ROUTES_PATHS.KATIA_PRODUCTION.LIST_ALL_ORDER}`,
    PRODUCTION_NEW_ORDER: `${ROUTES_PATHS.KATIA_PRODUCTION.NEW_ORDER}`,
    PRODUCTION_PRODUCTO_VIEW: `${ROUTES_PATHS.KATIA_PRODUCTION.PRODUCT_VIEW}`,
    PRODUCTION_PRODUCTO_VIEW_ID: `${ROUTES_PATHS.KATIA_PRODUCTION.PRODUCT_VIEW_ID}`,
    PRODUCTION_PRODUCTO_EDIT: `${ROUTES_PATHS.KATIA_PRODUCTION.PRODUCT_EDIT}`,
    PRODUCTION_PRODUCTO_DUPLICATE_ID: `${ROUTES_PATHS.KATIA_PRODUCTION.PRODUCT_DUPLICATE_ID}`,
    PRODUCTION_PRODUCTO_DUPLICATE: `${ROUTES_PATHS.KATIA_PRODUCTION.PRODUCT_DUPLICATE}`,
    PRODUCTION_PRODUCTO_EDIT_ID: `${ROUTES_PATHS.KATIA_PRODUCTION.PRODUCT_EDIT_ID}`,
    PRODUCTION_ORDER_VIEW: `${ROUTES_PATHS.KATIA_PRODUCTION.ORDER_VIEW}`,
    PRODUCTION_ORDER_VIEW_ID: `${ROUTES_PATHS.KATIA_PRODUCTION.ORDER_VIEW_ID}`,
    PRODUCTION_ORDER_EDIT: `${ROUTES_PATHS.KATIA_PRODUCTION.ORDER_EDIT}`,
    PRODUCTION_ORDER_EDIT_ID: `${ROUTES_PATHS.KATIA_PRODUCTION.ORDER_EDIT_ID}`,
    PRODUCTION_INSTRUCTIVO_CONFIG: `${ROUTES_PATHS.KATIA_PRODUCTION.INSTRUCTIVO_CONFIG}`,
    PRODUCTION_INSTRUCTIVO_CONFIG_ID: `${ROUTES_PATHS.KATIA_PRODUCTION.INSTRUCTIVO_CONFIG_ID}`,
    PRODUCTION_ORDER_INSTRUCTIVO: `${ROUTES_PATHS.KATIA_PRODUCTION.ORDER_INSTRUCTIVO}`,
    PRODUCTION_ORDER_INSTRUCTIVO_ID: `${ROUTES_PATHS.KATIA_PRODUCTION.ORDER_INSTRUCTIVO_ID}`,


    /**
     * BODEGA PATHS
     */
    BODEGA_DEFAULT: `${ROUTES_PATHS.KATIA_BODEGA.DEFAULT}`,
    BODEGA_HOME: `${ROUTES_PATHS.KATIA_BODEGA.DEFAULT}`,
    BODEGA_MATERIALES_LIST: `${ROUTES_PATHS.KATIA_BODEGA.MATERIALES_LIST}`,
    BODEGA_MATERIALES_NEW: `${ROUTES_PATHS.KATIA_BODEGA.MATERIALES_NEW}`,
    BODEGA_PRODUCTOS_LIST: `${ROUTES_PATHS.KATIA_BODEGA.PRODUCTOS_LIST}`,
    BODEGA_PRODUCTOS_NEW: `${ROUTES_PATHS.KATIA_BODEGA.PRODUCTOS_NEW}`,
    BODEGA_EXISTENCIAS_MATERIALES_LIST: `${ROUTES_PATHS.KATIA_BODEGA.EXISTENCIAS_MATERIALES_LIST}`,
    BODEGA_EXISTENCIAS_PRODUCTOS_LIST: `${ROUTES_PATHS.KATIA_BODEGA.EXISTENCIAS_PRODUCTOS_LIST}`,


    /**
     * COMERCIAL PATHS
     */
    COMERCIAL_DEFAULT: `${ROUTES_PATHS.KATIA_COMERCIAL.DEFAULT}`,
    COMERCIAL_HOME: `${ROUTES_PATHS.KATIA_COMERCIAL.DEFAULT}`,
    COMERCIAL_PROVEEDOR_INT_LIST: `${ROUTES_PATHS.KATIA_COMERCIAL.PROVEEDOR_INT_LIST}`,
    COMERCIAL_PROVEEDOR_INT_NEW: `${ROUTES_PATHS.KATIA_COMERCIAL.PROVEEDOR_INT_NEW}`,
    COMERCIAL_PROVEEDOR_INT_EDIT: `${ROUTES_PATHS.KATIA_COMERCIAL.PROVEEDOR_INT_EDIT}`,
    COMERCIAL_CLIENTE_INT_LIST: `${ROUTES_PATHS.KATIA_COMERCIAL.CLIENTE_INT_LIST}`,
    COMERCIAL_CLIENTE_INT_NEW: `${ROUTES_PATHS.KATIA_COMERCIAL.CLIENTE_INT_NEW}`,
    COMERCIAL_CLIENTE_INT_EDIT: `${ROUTES_PATHS.KATIA_COMERCIAL.CLIENTE_INT_EDIT}`,
    COMERCIAL_PROFORMA_EDIT: `${ROUTES_PATHS.KATIA_COMERCIAL.PROFORMA_EDIT}`,
    COMERCIAL_PROFORMA_LIST: `${ROUTES_PATHS.KATIA_COMERCIAL.PROFORMA_LIST}`,
    COMERCIAL_PROFORMA_NEW: `${ROUTES_PATHS.KATIA_COMERCIAL.PROFORMA_NEW}`,
    COMERCIAL_EMPAQUES_EDIT: `${ROUTES_PATHS.KATIA_COMERCIAL.EMPAQUES_EDIT}`,
    COMERCIAL_EMPAQUES_NEW: `${ROUTES_PATHS.KATIA_COMERCIAL.EMPAQUES_NEW}`,
    COMERCIAL_EMPAQUES_LIST: `${ROUTES_PATHS.KATIA_COMERCIAL.EMPAQUES_LIST}`,
    COMERCIAL_SEGUIMIENTO_LIST: `${ROUTES_PATHS.KATIA_COMERCIAL.SEGUIMIENTO_LIST}`,
    COMERCIAL_SEGUIMIENTO_NEW: `${ROUTES_PATHS.KATIA_COMERCIAL.SEGUIMIENTO_NEW}`,

    /**
     * EQUIPMENT PATHS
     */
    EQUIPO_DEFAULT: `${ROUTES_PATHS.KATIA_EQUIPO.DEFAULT}`,
    EQUIPO_HOME: `${ROUTES_PATHS.KATIA_EQUIPO.HOME}`,
    EQUIPO_LIST: `${ROUTES_PATHS.KATIA_EQUIPO.EQUIPO_LIST}`,
    EQUIPO_NEW: `${ROUTES_PATHS.KATIA_EQUIPO.EQUIPO_NEW}`,
    EQUIPO_EDIT_ID: `${ROUTES_PATHS.KATIA_EQUIPO.EQUIPO_EDIT_ID}`,
    EQUIPO_EDIT: `${ROUTES_PATHS.KATIA_EQUIPO.EQUIPO_EDIT}`,
    MANTENIMIENTO_EQUIPO_LIST: `${ROUTES_PATHS.KATIA_EQUIPO.MANTENIMIENTO_EQUIPO_LIST}`,
    MANTENIMIENTO_EQUIPO_NEW: `${ROUTES_PATHS.KATIA_EQUIPO.MANTENIMIENTO_EQUIPO_NEW}`,
    MANTENIMIENTO_EQUIPO_EDIT_ID: `${ROUTES_PATHS.KATIA_EQUIPO.MANTENIMIENTO_EQUIPO_EDIT_ID}`,
    MANTENIMIENTO_EQUIPO_EDIT: `${ROUTES_PATHS.KATIA_EQUIPO.MANTENIMIENTO_EQUIPO_EDIT}`,


    PROGRAMACION_DEFAULT: `${ROUTES_PATHS.KATIA_PROGRAMACION.DEFAULT}`,
    PROGRAMACION_HOME: `${ROUTES_PATHS.KATIA_PROGRAMACION.HOME}`,
    CRONOGRAMA_LIST: `${ROUTES_PATHS.KATIA_PROGRAMACION.CRONOGRAMA_LIST}`,
    CRONOGRAMA_DETALLE_LIST: `${ROUTES_PATHS.KATIA_PROGRAMACION.CRONOGRAMA_DETALLE_LIST}`,
    CRONOGRAMA_DETALLE_LIST_DATA: `${ROUTES_PATHS.KATIA_PROGRAMACION.CRONOGRAMA_DETALLE_LIST_DATA}`,
    CRONOGRAMA_NEW: `${ROUTES_PATHS.KATIA_PROGRAMACION.CRONOGRAMA_NEW}`,
    CRONOGRAMA_EDIT_ID: `${ROUTES_PATHS.KATIA_PROGRAMACION.CRONOGRAMA_EDIT_ID}`,
    CRONOGRAMA_EDIT: `${ROUTES_PATHS.KATIA_PROGRAMACION.CRONOGRAMA_EDIT}`,
    PLANEACION_LIST: `${ROUTES_PATHS.KATIA_PROGRAMACION.PLANEACION_LIST}`,
    PLANEACION_NEW: `${ROUTES_PATHS.KATIA_PROGRAMACION.PLANEACION_NEW}`,
    PLANEACION_EDIT_ID: `${ROUTES_PATHS.KATIA_PROGRAMACION.PLANEACION_EDIT_ID}`,
    PLANEACION_EDIT: `${ROUTES_PATHS.KATIA_PROGRAMACION.PLANEACION_EDIT}`,

    /**
     * SERVER PATHS
     */
    SERVER_ERROR_401: `${ROUTES_PATHS.SERVER.ERROR_401}`,
    SERVER_ERROR_403: `${ROUTES_PATHS.SERVER.ERROR_403}`,
    SERVER_ERROR_404: `${ROUTES_PATHS.SERVER.ERROR_404}`,
};

export const INTERNAL_ROUTES = {
    /**
     * AUTHENTICATION
     */
    AUTH_LOGIN: `/${INTERNAL_PATHS.AUTH_DEFAULT}/${INTERNAL_PATHS.AUTH_LOGIN}`,//auth/login
    AUTH_FORGOT_PASSWORD: `/${INTERNAL_PATHS.AUTH_DEFAULT}/${INTERNAL_PATHS.AUTH_FORGOT_PASSWORD}`,//auth/forgot-password
    AUTH_USER_COMPANY: `/${INTERNAL_PATHS.AUTH_DEFAULT}/${INTERNAL_PATHS.AUTH_USER_COMPANY}`,//panel/user/company
    /**
     * PANEL USER
     */
    PANEL_USER_HOME: `/${INTERNAL_PATHS.PANEL_DEFAULT}/${INTERNAL_PATHS.PANEL_USER_HOME}`,//panel/user
    PANEL_USER_LIST: `/${INTERNAL_PATHS.PANEL_DEFAULT}/${INTERNAL_PATHS.PANEL_USER_HOME}/${INTERNAL_PATHS.PANEL_USER_LIST}`,//panel/user/list
    PANEL_USER_NEW: `/${INTERNAL_PATHS.PANEL_DEFAULT}/${INTERNAL_PATHS.PANEL_USER_HOME}/${INTERNAL_PATHS.PANEL_USER_NEW}`,//panel/user/new
    PANEL_USER_EDIT: `/${INTERNAL_PATHS.PANEL_DEFAULT}/${INTERNAL_PATHS.PANEL_USER_HOME}/${INTERNAL_PATHS.PANEL_USER_EDIT}`,//panel/user/edit
    PANEL_USER_EDIT_ID: `/${INTERNAL_PATHS.PANEL_DEFAULT}/${INTERNAL_PATHS.PANEL_USER_HOME}/${INTERNAL_PATHS.PANEL_USER_EDIT}/${INTERNAL_PATHS.PANEL_USER_EDIT_ID}`,//panel/user/edit/:id
    PANEL_USER_UNAUTHORIZED: `/${INTERNAL_PATHS.PANEL_DEFAULT}/${INTERNAL_PATHS.PANEL_USER_HOME}/${INTERNAL_PATHS.PANEL_USER_UNAUTHORIZED}`,//panel/user/unauthorized
    PANEL_USER_ROLES_LIST: `/${INTERNAL_PATHS.PANEL_DEFAULT}/${INTERNAL_PATHS.PANEL_USER_HOME}/${INTERNAL_PATHS.PANEL_USER_ROLES_LIST}`,//panel/user/roles-list
    PANEL_USER_ROLE_ASSIGN: `/${INTERNAL_PATHS.PANEL_DEFAULT}/${INTERNAL_PATHS.PANEL_USER_HOME}/${INTERNAL_PATHS.PANEL_USER_ROLE_ASSIGN}`,//panel/user/role-assign
    /**
     * PANEL USER PATHS
     */
    USER_GENERAL_UPDATE_PASSWORD: `/${INTERNAL_PATHS.USER_GENERAL_DEFAULT}/${INTERNAL_PATHS.USER_GENERAL_HOME}/${INTERNAL_PATHS.USER_GENERAL_UPDATE_PASSWORD}`,//user-general/update-password
    USER_GENERAL_PROFILE: `/${INTERNAL_PATHS.USER_GENERAL_DEFAULT}/${INTERNAL_PATHS.USER_GENERAL_HOME}/${INTERNAL_PATHS.USER_GENERAL_PROFILE}`,//user-general/user-profle

    /**
     * MATERIALES KATIA
     */
    KATIA_MATERIALS_LIST: `/${INTERNAL_PATHS.MATERIAL_DEFAULT}/${INTERNAL_PATHS.MATERIAL_HOME}/${INTERNAL_PATHS.MATERIAL_LIST}`,
    KATIA_MATERIALS_NEW: `/${INTERNAL_PATHS.MATERIAL_DEFAULT}/${INTERNAL_PATHS.MATERIAL_HOME}/${INTERNAL_PATHS.MATERIAL_NEW}`,
    KATIA_MATERIALS_HISTORICAL: `/${INTERNAL_PATHS.MATERIAL_DEFAULT}/${INTERNAL_PATHS.MATERIAL_HOME}/${INTERNAL_PATHS.MATERIAL_HISTORICAL}`,
    KATIA_MATERIALS_EDIT: `/${INTERNAL_PATHS.MATERIAL_DEFAULT}/${INTERNAL_PATHS.MATERIAL_HOME}/${INTERNAL_PATHS.MATERIAL_EDIT}`,

    /**
     * PROVEEDORES KATIA
     */
    KATIA_PROVIDERS_LIST: `/${INTERNAL_PATHS.PROVIDER_DEFAULT}/${INTERNAL_PATHS.PROVIDER_HOME}/${INTERNAL_PATHS.PROVIDER_LIST}`,
    KATIA_PROVIDERS_NEW: `/${INTERNAL_PATHS.PROVIDER_DEFAULT}/${INTERNAL_PATHS.PROVIDER_HOME}/${INTERNAL_PATHS.PROVIDER_NEW}`,
    KATIA_PROVIDERS_EDIT: `/${INTERNAL_PATHS.PROVIDER_DEFAULT}/${INTERNAL_PATHS.PROVIDER_HOME}/${INTERNAL_PATHS.PROVIDER_EDIT}`,
    KATIA_PROVIDERS_MATERIALES_EDIT_ID: `/${INTERNAL_PATHS.PROVIDER_DEFAULT}/${INTERNAL_PATHS.PROVIDER_HOME}/${INTERNAL_PATHS.PROVIDER_MATERIALES_EDIT_ID}`,
    KATIA_PROVIDERS_MATERIALES_EDIT: `/${INTERNAL_PATHS.PROVIDER_DEFAULT}/${INTERNAL_PATHS.PROVIDER_HOME}/${INTERNAL_PATHS.PROVIDER_MATERIALES_EDIT}`,

    /**
     * ORDEN COMPRA KATIA
     */
    KATIA_ORDEN_COMPRA_LIST: `/${INTERNAL_PATHS.ORDEN_COMPRA_DEFAULT}/${INTERNAL_PATHS.ORDEN_COMPRA_HOME}/${INTERNAL_PATHS.ORDEN_COMPRA_LIST}`,
    KATIA_ORDEN_COMPRA_NEW: `/${INTERNAL_PATHS.ORDEN_COMPRA_DEFAULT}/${INTERNAL_PATHS.ORDEN_COMPRA_HOME}/${INTERNAL_PATHS.ORDEN_COMPRA_NEW}`,
    KATIA_ORDEN_COMPRA_ENTER_ORDER: `/${INTERNAL_PATHS.ORDEN_COMPRA_DEFAULT}/${INTERNAL_PATHS.ORDEN_COMPRA_HOME}/${INTERNAL_PATHS.ORDEN_COMPRA_ENTER_ORDER}`,
    KATIA_ORDEN_COMPRA_ENTER_ORDER_ID: `/${INTERNAL_PATHS.ORDEN_COMPRA_DEFAULT}/${INTERNAL_PATHS.ORDEN_COMPRA_HOME}/${INTERNAL_PATHS.ORDEN_COMPRA_ENTER_ORDER_ID}`,
    KATIA_ORDEN_COMPRA_CUARENTENA: `/${INTERNAL_PATHS.ORDEN_COMPRA_DEFAULT}/${INTERNAL_PATHS.ORDEN_COMPRA_HOME}/${INTERNAL_PATHS.ORDEN_COMPRA_CUARENTENA}`,
    KATIA_ORDEN_COMPRA_CUARENTENA_ID: `/${INTERNAL_PATHS.ORDEN_COMPRA_DEFAULT}/${INTERNAL_PATHS.ORDEN_COMPRA_HOME}/${INTERNAL_PATHS.ORDEN_COMPRA_CUARENTENA_ID}`,
    KATIA_ORDEN_COMPRA_CUARENTENA_EDIT: `/${INTERNAL_PATHS.ORDEN_COMPRA_DEFAULT}/${INTERNAL_PATHS.ORDEN_COMPRA_HOME}/${INTERNAL_PATHS.ORDEN_COMPRA_CUARENTENA_EDIT}`,
    KATIA_ORDEN_COMPRA_CUARENTENA_EDIT_ID: `/${INTERNAL_PATHS.ORDEN_COMPRA_DEFAULT}/${INTERNAL_PATHS.ORDEN_COMPRA_HOME}/${INTERNAL_PATHS.ORDEN_COMPRA_CUARENTENA_EDIT_ID}`,
    KATIA_ORDEN_COMPRA_LIST_MATERIALS: `/${INTERNAL_PATHS.ORDEN_COMPRA_DEFAULT}/${INTERNAL_PATHS.ORDEN_COMPRA_HOME}/${INTERNAL_PATHS.ORDEN_COMPRA_LIST_MATERIALS}`,
    KATIA_ORDEN_COMPRA_LIST_MATERIALS_ID: `/${INTERNAL_PATHS.ORDEN_COMPRA_DEFAULT}/${INTERNAL_PATHS.ORDEN_COMPRA_HOME}/${INTERNAL_PATHS.ORDEN_COMPRA_LIST_MATERIALS_ID}`,
    KATIA_ORDEN_COMPRA_LIST_EDIT: `/${INTERNAL_PATHS.ORDEN_COMPRA_DEFAULT}/${INTERNAL_PATHS.ORDEN_COMPRA_HOME}/${INTERNAL_PATHS.ORDEN_COMPRA_LIST_EDIT}`,
    KATIA_ORDEN_COMPRA_LIST_EDIT_ID: `/${INTERNAL_PATHS.ORDEN_COMPRA_DEFAULT}/${INTERNAL_PATHS.ORDEN_COMPRA_HOME}/${INTERNAL_PATHS.ORDEN_COMPRA_LIST_EDIT_ID}`,
    KATIA_ORDEN_COMPRA_PDF_EDIT: `/${INTERNAL_PATHS.ORDEN_COMPRA_DEFAULT}/${INTERNAL_PATHS.ORDEN_COMPRA_HOME}/${INTERNAL_PATHS.ORDEN_COMPRA_EDITAR_PDF_ID}`,
    KATIA_ORDEN_COMPRA_UNITARIO: `/${INTERNAL_PATHS.ORDEN_COMPRA_DEFAULT}/${INTERNAL_PATHS.ORDEN_COMPRA_HOME}/${INTERNAL_PATHS.ORDEN_COMPRA_UNITARIO}`,
    KATIA_ORDEN_COMPRA_UNITARIO_ID: `/${INTERNAL_PATHS.ORDEN_COMPRA_DEFAULT}/${INTERNAL_PATHS.ORDEN_COMPRA_HOME}/${INTERNAL_PATHS.ORDEN_COMPRA_UNITARIO_ID}`,
    KATIA_ORDEN_COMPRA_UNITARIO_EDIT_ID: `/${INTERNAL_PATHS.ORDEN_COMPRA_DEFAULT}/${INTERNAL_PATHS.ORDEN_COMPRA_HOME}/${INTERNAL_PATHS.ORDEN_COMPRA_UNITARIO_EDIT_ID}`,
    KATIA_ORDEN_COMPRA_UNITARIO_EDIT: `/${INTERNAL_PATHS.ORDEN_COMPRA_DEFAULT}/${INTERNAL_PATHS.ORDEN_COMPRA_HOME}/${INTERNAL_PATHS.ORDEN_COMPRA_UNITARIO_EDIT}`,
    KATIA_ORDEN_COMPRA_NOTA_CREDITO: `/${INTERNAL_PATHS.ORDEN_COMPRA_DEFAULT}/${INTERNAL_PATHS.ORDEN_COMPRA_HOME}/${INTERNAL_PATHS.ORDEN_COMPRA_NOTA_CREDITO}`,
    KATIA_ORDEN_COMPRA_NOTA_CREDITO_ID: `/${INTERNAL_PATHS.ORDEN_COMPRA_DEFAULT}/${INTERNAL_PATHS.ORDEN_COMPRA_HOME}/${INTERNAL_PATHS.ORDEN_COMPRA_NOTA_CREDITO_ID}`,
    /**
     * COMPANY ROUTES
     */
    COMPANY_LIST: `/${INTERNAL_PATHS.COMPANY_DEFAULT}/${INTERNAL_PATHS.COMPANY_HOME}/${INTERNAL_PATHS.COMPANY_LIST}`,//company/company-list
    COMPANY_NEW: `/${INTERNAL_PATHS.COMPANY_DEFAULT}/${INTERNAL_PATHS.COMPANY_HOME}/${INTERNAL_PATHS.COMPANY_NEW}`,//company/company-new
    COMPANY_EDIT: `/${INTERNAL_PATHS.COMPANY_DEFAULT}/${INTERNAL_PATHS.COMPANY_HOME}/${INTERNAL_PATHS.COMPANY_EDIT}`,//company/company-edit
    COMPANY_EDIT_ID: `/${INTERNAL_PATHS.COMPANY_DEFAULT}/${INTERNAL_PATHS.COMPANY_HOME}/${INTERNAL_PATHS.COMPANY_EDIT}/${INTERNAL_PATHS.COMPANY_EDIT_ID}`,//company/edit/:id
    COMPANY_ASSIGN_MODULE: `/${INTERNAL_PATHS.COMPANY_DEFAULT}/${INTERNAL_PATHS.COMPANY_HOME}/${INTERNAL_PATHS.COMPANY_ASSIGN_MODULE}`,//company/company/assign-module
    COMPANY_LIST_USERS: `/${INTERNAL_PATHS.COMPANY_DEFAULT}/${INTERNAL_PATHS.COMPANY_HOME}/${INTERNAL_PATHS.COMPANY_LIST_USERS}`,//company/list-user
    COMPANY_LIST_USERS_ID: `/${INTERNAL_PATHS.COMPANY_DEFAULT}/${INTERNAL_PATHS.COMPANY_HOME}/${INTERNAL_PATHS.COMPANY_LIST_USERS}/${INTERNAL_PATHS.COMPANY_LIST_USERS_ID}`,//company/list-user/:id

    /**
     * MODULE ROUTES
     */
    MODULE_LIST: `/${INTERNAL_PATHS.MODULE_DEFAULT}/${INTERNAL_PATHS.MODULE_HOME}/${INTERNAL_PATHS.MODULE_LIST}`,//module/module-list
    MODULE_NEW: `/${INTERNAL_PATHS.MODULE_DEFAULT}/${INTERNAL_PATHS.MODULE_HOME}/${INTERNAL_PATHS.MODULE_NEW}`,//module/module-new
    MODULE_EDIT: `/${INTERNAL_PATHS.MODULE_DEFAULT}/${INTERNAL_PATHS.MODULE_HOME}/${INTERNAL_PATHS.MODULE_EDIT}`,//company/company-edit
    MODULE_EDIT_ID: `/${INTERNAL_PATHS.MODULE_DEFAULT}/${INTERNAL_PATHS.MODULE_HOME}/${INTERNAL_PATHS.MODULE_EDIT}/${INTERNAL_PATHS.MODULE_EDIT_ID}`,//company/edit/:id
    /**
     * SUBMODULE ROUTES
     */
    SUBMODULE_LIST: `/${INTERNAL_PATHS.MODULE_DEFAULT}/${INTERNAL_PATHS.MODULE_HOME}/${INTERNAL_PATHS.MODULE_SUBMODULE}`,//module/module-list
    /**
     * ROLE ROUTES
     */
    ROLE_LIST: `/${INTERNAL_PATHS.ROLE_DEFAULT}/${INTERNAL_PATHS.ROLE_HOME}/${INTERNAL_PATHS.ROLE_LIST}`,//role/role-list
    ROLE_NEW: `/${INTERNAL_PATHS.ROLE_DEFAULT}/${INTERNAL_PATHS.ROLE_HOME}/${INTERNAL_PATHS.ROLE_NEW}`,//role/role-new
    ROLE_EDIT: `/${INTERNAL_PATHS.ROLE_DEFAULT}/${INTERNAL_PATHS.ROLE_HOME}/${INTERNAL_PATHS.ROLE_EDIT}`,//role/rle-edit
    ROLE_EDIT_ID: `/${INTERNAL_PATHS.ROLE_DEFAULT}/${INTERNAL_PATHS.ROLE_HOME}/${INTERNAL_PATHS.ROLE_EDIT}/${INTERNAL_PATHS.ROLE_EDIT_ID}`,//role/edit/:id
    ROLE_SUBMODULE_ASSIGN: `/${INTERNAL_PATHS.ROLE_DEFAULT}/${INTERNAL_PATHS.ROLE_HOME}/${INTERNAL_PATHS.ROLE_SUBMODULE_ASSIGN}`,//role/submodule-assign
    ROLE_SUBMODULE_ASSIGN_ID: `/${INTERNAL_PATHS.ROLE_DEFAULT}/${INTERNAL_PATHS.ROLE_HOME}/${INTERNAL_PATHS.ROLE_SUBMODULE_ASSIGN_ID}`,//role/submodule-assign
    PERMISSIONS_ROLE: `/${INTERNAL_PATHS.ROLE_DEFAULT}/${INTERNAL_PATHS.ROLE_HOME}/${INTERNAL_PATHS.PERMISSIONS_ROLE}`,//role/rle-edit
    PERMISSIONS_ROLE_ID: `/${INTERNAL_PATHS.ROLE_DEFAULT}/${INTERNAL_PATHS.ROLE_HOME}/${INTERNAL_PATHS.ROLE_EDIT}/${INTERNAL_PATHS.PERMISSIONS_ROLE_ID}`,//role/edit/:id
    /**
     * ROLE ROUTES
     */
    SUPERADMIN_USERS: `/${INTERNAL_PATHS.SUPERADMIN_DEFAULT}/${INTERNAL_PATHS.SUPERADMIN_HOME}/${INTERNAL_PATHS.SUPERADMIN_USERS}`,//superadmin/users
    SUPERADMIN_MODULES: `/${INTERNAL_PATHS.SUPERADMIN_DEFAULT}/${INTERNAL_PATHS.SUPERADMIN_HOME}/${INTERNAL_PATHS.SUPERADMIN_MODULES}`,//superadmin/modules

    /**
     * QUALITY ROUTES
     */
    KATIA_QUALITY_ALL_MATERIALS_LIST: `/${INTERNAL_PATHS.QUALITY_DEFAULT}/${INTERNAL_PATHS.QUALITY_HOME}/${INTERNAL_PATHS.QUALITY_ALL_MATERIALS_LIST}`,
    KATIA_QUALITY_MATERIALS_TO_CHECK_LIST: `/${INTERNAL_PATHS.QUALITY_DEFAULT}/${INTERNAL_PATHS.QUALITY_HOME}/${INTERNAL_PATHS.QUALITY_MATERIALS_TO_CHECK_LIST}`,
    KATIA_QUALITY_MATERIAL_VIEW: `/${INTERNAL_PATHS.QUALITY_DEFAULT}/${INTERNAL_PATHS.QUALITY_HOME}/${INTERNAL_PATHS.QUALITY_MATERIAL_VIEW}`,
    KATIA_QUALITY_MATERIAL_VIEW_ID: `/${INTERNAL_PATHS.QUALITY_DEFAULT}/${INTERNAL_PATHS.QUALITY_HOME}/${INTERNAL_PATHS.QUALITY_MATERIAL_VIEW_ID}`,
    KATIA_QUALITY_INSPECTION_ALL_MATERIALS_LIST: `/${INTERNAL_PATHS.QUALITY_DEFAULT}/${INTERNAL_PATHS.QUALITY_HOME}/${INTERNAL_PATHS.QUALITY_INSPECTION_ALL_MATERIALS_LIST}`,
    KATIA_QUALITY_INSPECTION_PROD_MEE_NEW: `/${INTERNAL_PATHS.QUALITY_DEFAULT}/${INTERNAL_PATHS.QUALITY_HOME}/${INTERNAL_PATHS.QUALITY_INSPECTION_PROD_MEE_NEW}`,
    KATIA_QUALITY_INSPECTION_PROD_MEE_NEW_ID: `/${INTERNAL_PATHS.QUALITY_DEFAULT}/${INTERNAL_PATHS.QUALITY_HOME}/${INTERNAL_PATHS.QUALITY_INSPECTION_PROD_MEE_NEW_ID}`,
    KATIA_QUALITY_INSPECTION_PROD_MEE_EDIT: `/${INTERNAL_PATHS.QUALITY_DEFAULT}/${INTERNAL_PATHS.QUALITY_HOME}/${INTERNAL_PATHS.QUALITY_INSPECTION_PROD_MEE_EDIT}`,
    KATIA_QUALITY_INSPECTION_PROD_MEE_EDIT_ID: `/${INTERNAL_PATHS.QUALITY_DEFAULT}/${INTERNAL_PATHS.QUALITY_HOME}/${INTERNAL_PATHS.QUALITY_INSPECTION_PROD_MEE_EDIT_ID}`,
    KATIA_QUALITY_INSPECTION_PROD_MP_NEW: `/${INTERNAL_PATHS.QUALITY_DEFAULT}/${INTERNAL_PATHS.QUALITY_HOME}/${INTERNAL_PATHS.QUALITY_INSPECTION_PROD_MP_NEW}`,
    KATIA_QUALITY_INSPECTION_PROD_MP_NEW_ID: `/${INTERNAL_PATHS.QUALITY_DEFAULT}/${INTERNAL_PATHS.QUALITY_HOME}/${INTERNAL_PATHS.QUALITY_INSPECTION_PROD_MP_NEW_ID}`,
    KATIA_QUALITY_INSPECTION_PROD_MP_EDIT: `/${INTERNAL_PATHS.QUALITY_DEFAULT}/${INTERNAL_PATHS.QUALITY_HOME}/${INTERNAL_PATHS.QUALITY_INSPECTION_PROD_MP_EDIT}`,
    KATIA_QUALITY_INSPECTION_PROD_MP_EDIT_ID: `/${INTERNAL_PATHS.QUALITY_DEFAULT}/${INTERNAL_PATHS.QUALITY_HOME}/${INTERNAL_PATHS.QUALITY_INSPECTION_PROD_MP_EDIT_ID}`,
    KATIA_QUALITY_LIBERACION_AGUA_LIST: `/${INTERNAL_PATHS.QUALITY_DEFAULT}/${INTERNAL_PATHS.QUALITY_HOME}/${INTERNAL_PATHS.QUALITY_LIBERACION_AGUA_LIST}`,
    KATIA_QUALITY_LIBERACION_AGUA_NEW: `/${INTERNAL_PATHS.QUALITY_DEFAULT}/${INTERNAL_PATHS.QUALITY_HOME}/${INTERNAL_PATHS.QUALITY_LIBERACION_AGUA_NEW}`,
    KATIA_QUALITY_LIBERACION_AGUA_EDIT: `/${INTERNAL_PATHS.QUALITY_DEFAULT}/${INTERNAL_PATHS.QUALITY_HOME}/${INTERNAL_PATHS.QUALITY_LIBERACION_AGUA_EDIT}`,
    KATIA_QUALITY_LIBERACION_AGUA_EDIT_ID: `/${INTERNAL_PATHS.QUALITY_DEFAULT}/${INTERNAL_PATHS.QUALITY_HOME}/${INTERNAL_PATHS.QUALITY_LIBERACION_AGUA_EDIT_ID}`,

    /**
     * PRODUCTION ROUTES
     */
    KATIA_PRODUCTION_LIST_ALL_PRODUCT_SHEETS: `/${INTERNAL_PATHS.PRODUCTION_DEFAULT}/${INTERNAL_PATHS.PRODUCTION_HOME}/${INTERNAL_PATHS.PRODUCTION_LIST_ALL_PRODUCT_SHEETS}`,
    KATIA_PRODUCTION_NEW: `/${INTERNAL_PATHS.PRODUCTION_DEFAULT}/${INTERNAL_PATHS.PRODUCTION_HOME}/${INTERNAL_PATHS.PRODUCTION_NEW}`,//produccion/new
    KATIA_PRODUCTION_LIST_ALL_ORDER: `/${INTERNAL_PATHS.PRODUCTION_DEFAULT}/${INTERNAL_PATHS.PRODUCTION_HOME}/${INTERNAL_PATHS.PRODUCTION_LIST_ALL_ORDER}`,
    KATIA_PRODUCTION_NEW_ORDER: `/${INTERNAL_PATHS.PRODUCTION_DEFAULT}/${INTERNAL_PATHS.PRODUCTION_HOME}/${INTERNAL_PATHS.PRODUCTION_NEW_ORDER}`,//produccion/new-order
    KATIA_PRODUCTION_PRODUCTO_VIEW: `/${INTERNAL_PATHS.PRODUCTION_DEFAULT}/${INTERNAL_PATHS.PRODUCTION_HOME}/${INTERNAL_PATHS.PRODUCTION_PRODUCTO_VIEW}`,
    KATIA_PRODUCTION_PRODUCTO_VIEW_ID: `/${INTERNAL_PATHS.PRODUCTION_DEFAULT}/${INTERNAL_PATHS.PRODUCTION_HOME}/${INTERNAL_PATHS.PRODUCTION_PRODUCTO_VIEW_ID}`,
    KATIA_PRODUCTION_PRODUCTO_EDIT: `/${INTERNAL_PATHS.PRODUCTION_DEFAULT}/${INTERNAL_PATHS.PRODUCTION_HOME}/${INTERNAL_PATHS.PRODUCTION_PRODUCTO_EDIT}`,
    KATIA_PRODUCTION_PRODUCTO_DUPLICATE_ID: `/${INTERNAL_PATHS.PRODUCTION_DEFAULT}/${INTERNAL_PATHS.PRODUCTION_HOME}/${INTERNAL_PATHS.PRODUCTION_PRODUCTO_DUPLICATE_ID}`,
    KATIA_PRODUCTION_PRODUCTO_DUPLICATE: `/${INTERNAL_PATHS.PRODUCTION_DEFAULT}/${INTERNAL_PATHS.PRODUCTION_HOME}/${INTERNAL_PATHS.PRODUCTION_PRODUCTO_DUPLICATE}`,
    KATIA_PRODUCTION_PRODUCTO_EDIT_ID: `/${INTERNAL_PATHS.PRODUCTION_DEFAULT}/${INTERNAL_PATHS.PRODUCTION_HOME}/${INTERNAL_PATHS.PRODUCTION_PRODUCTO_EDIT_ID}`,
    KATIA_PRODUCTION_ORDER_VIEW: `/${INTERNAL_PATHS.PRODUCTION_DEFAULT}/${INTERNAL_PATHS.PRODUCTION_HOME}/${INTERNAL_PATHS.PRODUCTION_ORDER_VIEW}`,
    KATIA_PRODUCTION_ORDER_VIEW_ID: `/${INTERNAL_PATHS.PRODUCTION_DEFAULT}/${INTERNAL_PATHS.PRODUCTION_HOME}/${INTERNAL_PATHS.PRODUCTION_ORDER_VIEW_ID}`,
    KATIA_PRODUCTION_ORDER_EDIT: `/${INTERNAL_PATHS.PRODUCTION_DEFAULT}/${INTERNAL_PATHS.PRODUCTION_HOME}/${INTERNAL_PATHS.PRODUCTION_ORDER_EDIT}`,
    KATIA_PRODUCTION_ORDER_EDIT_ID: `/${INTERNAL_PATHS.PRODUCTION_DEFAULT}/${INTERNAL_PATHS.PRODUCTION_HOME}/${INTERNAL_PATHS.PRODUCTION_ORDER_EDIT_ID}`,
    KATIA_PRODUCTION_INSTRUCTIVO_CONFIG: `/${INTERNAL_PATHS.PRODUCTION_DEFAULT}/${INTERNAL_PATHS.PRODUCTION_HOME}/${INTERNAL_PATHS.PRODUCTION_INSTRUCTIVO_CONFIG}`,
    KATIA_PRODUCTION_INSTRUCTIVO_CONFIG_ID: `/${INTERNAL_PATHS.PRODUCTION_DEFAULT}/${INTERNAL_PATHS.PRODUCTION_HOME}/${INTERNAL_PATHS.PRODUCTION_INSTRUCTIVO_CONFIG_ID}`,
    KATIA_PRODUCTION_ORDER_INSTRUCTIVO: `/${INTERNAL_PATHS.PRODUCTION_DEFAULT}/${INTERNAL_PATHS.PRODUCTION_HOME}/${INTERNAL_PATHS.PRODUCTION_ORDER_INSTRUCTIVO}`,
    KATIA_PRODUCTION_ORDER_INSTRUCTIVO_ID: `/${INTERNAL_PATHS.PRODUCTION_DEFAULT}/${INTERNAL_PATHS.PRODUCTION_HOME}/${INTERNAL_PATHS.PRODUCTION_ORDER_INSTRUCTIVO_ID}`,


    KATIA_BODEGA_MATERIALES_LIST: `/${INTERNAL_PATHS.BODEGA_DEFAULT}/${INTERNAL_PATHS.BODEGA_HOME}/${INTERNAL_PATHS.BODEGA_MATERIALES_LIST}`,
    KATIA_BODEGA_MATERIALES_NEW: `/${INTERNAL_PATHS.BODEGA_DEFAULT}/${INTERNAL_PATHS.BODEGA_HOME}/${INTERNAL_PATHS.BODEGA_MATERIALES_NEW}`,
    KATIA_BODEGA_PRODUCTOS_LIST: `/${INTERNAL_PATHS.BODEGA_DEFAULT}/${INTERNAL_PATHS.BODEGA_HOME}/${INTERNAL_PATHS.BODEGA_PRODUCTOS_LIST}`,
    KATIA_BODEGA_PRODUCTOS_NEW: `/${INTERNAL_PATHS.BODEGA_DEFAULT}/${INTERNAL_PATHS.BODEGA_HOME}/${INTERNAL_PATHS.BODEGA_PRODUCTOS_NEW}`,
    KATIA_BODEGA_EXISTENCIAS_MATERIALES_LIST: `/${INTERNAL_PATHS.BODEGA_DEFAULT}/${INTERNAL_PATHS.BODEGA_HOME}/${INTERNAL_PATHS.BODEGA_EXISTENCIAS_MATERIALES_LIST}`,
    KATIA_BODEGA_EXISTENCIAS_PRODUCTOS_LIST: `/${INTERNAL_PATHS.BODEGA_DEFAULT}/${INTERNAL_PATHS.BODEGA_HOME}/${INTERNAL_PATHS.BODEGA_EXISTENCIAS_PRODUCTOS_LIST}`,


    KATIA_COMERCIAL_PROVEEDOR_INT_LIST: `/${INTERNAL_PATHS.COMERCIAL_DEFAULT}/${INTERNAL_PATHS.COMERCIAL_HOME}/${INTERNAL_PATHS.COMERCIAL_PROVEEDOR_INT_LIST}`,
    KATIA_COMERCIAL_PROVEEDOR_INT_NEW: `/${INTERNAL_PATHS.COMERCIAL_DEFAULT}/${INTERNAL_PATHS.COMERCIAL_HOME}/${INTERNAL_PATHS.COMERCIAL_PROVEEDOR_INT_NEW}`,
    KATIA_COMERCIAL_PROVEEDOR_INT_EDIT: `/${INTERNAL_PATHS.COMERCIAL_DEFAULT}/${INTERNAL_PATHS.COMERCIAL_HOME}/${INTERNAL_PATHS.COMERCIAL_PROVEEDOR_INT_EDIT}`,
    KATIA_COMERCIAL_CLIENTE_INT_LIST: `/${INTERNAL_PATHS.COMERCIAL_DEFAULT}/${INTERNAL_PATHS.COMERCIAL_HOME}/${INTERNAL_PATHS.COMERCIAL_CLIENTE_INT_LIST}`,
    KATIA_COMERCIAL_CLIENTE_INT_NEW: `/${INTERNAL_PATHS.COMERCIAL_DEFAULT}/${INTERNAL_PATHS.COMERCIAL_HOME}/${INTERNAL_PATHS.COMERCIAL_CLIENTE_INT_NEW}`,
    KATIA_COMERCIAL_CLIENTE_INT_EDIT: `/${INTERNAL_PATHS.COMERCIAL_DEFAULT}/${INTERNAL_PATHS.COMERCIAL_HOME}/${INTERNAL_PATHS.COMERCIAL_CLIENTE_INT_EDIT}`,
    KATIA_COMERCIAL_PROFORMA_NEW: `/${INTERNAL_PATHS.COMERCIAL_DEFAULT}/${INTERNAL_PATHS.COMERCIAL_HOME}/${INTERNAL_PATHS.COMERCIAL_PROFORMA_NEW}`,
    KATIA_COMERCIAL_PROFORMA_EDIT: `/${INTERNAL_PATHS.COMERCIAL_DEFAULT}/${INTERNAL_PATHS.COMERCIAL_HOME}/${INTERNAL_PATHS.COMERCIAL_PROFORMA_EDIT}`,
    KATIA_COMERCIAL_PROFORMA_LIST: `/${INTERNAL_PATHS.COMERCIAL_DEFAULT}/${INTERNAL_PATHS.COMERCIAL_HOME}/${INTERNAL_PATHS.COMERCIAL_PROFORMA_LIST}`,
    KATIA_COMERCIAL_EMPAQUES_EDIT: `/${INTERNAL_PATHS.COMERCIAL_DEFAULT}/${INTERNAL_PATHS.COMERCIAL_HOME}/${INTERNAL_PATHS.COMERCIAL_EMPAQUES_EDIT}`,
    KATIA_COMERCIAL_EMPAQUES_NEW: `/${INTERNAL_PATHS.COMERCIAL_DEFAULT}/${INTERNAL_PATHS.COMERCIAL_HOME}/${INTERNAL_PATHS.COMERCIAL_EMPAQUES_NEW}`,
    KATIA_COMERCIAL_EMPAQUES_LIST: `/${INTERNAL_PATHS.COMERCIAL_DEFAULT}/${INTERNAL_PATHS.COMERCIAL_HOME}/${INTERNAL_PATHS.COMERCIAL_EMPAQUES_LIST}`,
    KATIA_COMERCIAL_SEGUIMIENTO_LIST: `/${INTERNAL_PATHS.COMERCIAL_DEFAULT}/${INTERNAL_PATHS.COMERCIAL_HOME}/${INTERNAL_PATHS.COMERCIAL_SEGUIMIENTO_LIST}`,
    KATIA_COMERCIAL_SEGUIMIENTO_NEW: `/${INTERNAL_PATHS.COMERCIAL_DEFAULT}/${INTERNAL_PATHS.COMERCIAL_HOME}/${INTERNAL_PATHS.COMERCIAL_SEGUIMIENTO_NEW}`,


    KATIA_EQUIPOS_LIST: `/${INTERNAL_PATHS.EQUIPO_DEFAULT}/${INTERNAL_PATHS.EQUIPO_HOME}/${INTERNAL_PATHS.EQUIPO_LIST}`,
    KATIA_EQUIPO_NEW: `/${INTERNAL_PATHS.EQUIPO_DEFAULT}/${INTERNAL_PATHS.EQUIPO_HOME}/${INTERNAL_PATHS.EQUIPO_NEW}`,
    KATIA_EQUIPOS_EDIT_ID: `/${INTERNAL_PATHS.EQUIPO_DEFAULT}/${INTERNAL_PATHS.EQUIPO_HOME}/${INTERNAL_PATHS.EQUIPO_EDIT_ID}`,
    KATIA_EQUIPOS_EDIT: `/${INTERNAL_PATHS.EQUIPO_DEFAULT}/${INTERNAL_PATHS.EQUIPO_HOME}/${INTERNAL_PATHS.EQUIPO_EDIT}`,
    KATIA_MANTENIMIENTO_EQUIPO_LIST: `/${INTERNAL_PATHS.EQUIPO_DEFAULT}/${INTERNAL_PATHS.EQUIPO_HOME}/${INTERNAL_PATHS.MANTENIMIENTO_EQUIPO_LIST}`,
    KATIA_MANTENIMIENTO_EQUIPO_NEW: `/${INTERNAL_PATHS.EQUIPO_DEFAULT}/${INTERNAL_PATHS.EQUIPO_HOME}/${INTERNAL_PATHS.MANTENIMIENTO_EQUIPO_NEW}`,
    KATIA_MANTENIMIENTO_EQUIPOS_EDIT_ID: `/${INTERNAL_PATHS.EQUIPO_DEFAULT}/${INTERNAL_PATHS.EQUIPO_HOME}/${INTERNAL_PATHS.MANTENIMIENTO_EQUIPO_EDIT_ID}`,
    KATIA_MANTENIMIENTO_EQUIPOS_EDIT: `/${INTERNAL_PATHS.EQUIPO_DEFAULT}/${INTERNAL_PATHS.EQUIPO_HOME}/${INTERNAL_PATHS.MANTENIMIENTO_EQUIPO_EDIT}`,


    KATIA_CRONOGRAMA_LIST: `/${INTERNAL_PATHS.PROGRAMACION_DEFAULT}/${INTERNAL_PATHS.PROGRAMACION_HOME}/${INTERNAL_PATHS.CRONOGRAMA_LIST}`,
    KATIA_CRONOGRAMA_DETALLE_LIST: `/${INTERNAL_PATHS.PROGRAMACION_DEFAULT}/${INTERNAL_PATHS.PROGRAMACION_HOME}/${INTERNAL_PATHS.CRONOGRAMA_DETALLE_LIST}`,
    KATIA_CRONOGRAMA_DETALLE_LIST_DATA: `/${INTERNAL_PATHS.PROGRAMACION_DEFAULT}/${INTERNAL_PATHS.PROGRAMACION_HOME}/${INTERNAL_PATHS.CRONOGRAMA_DETALLE_LIST_DATA}`,
    KATIA_CRONOGRAMA_NEW: `/${INTERNAL_PATHS.PROGRAMACION_DEFAULT}/${INTERNAL_PATHS.PROGRAMACION_HOME}/${INTERNAL_PATHS.CRONOGRAMA_NEW}`,
    KATIA_CRONOGRAMA_EDIT_ID: `/${INTERNAL_PATHS.PROGRAMACION_DEFAULT}/${INTERNAL_PATHS.PROGRAMACION_HOME}/${INTERNAL_PATHS.CRONOGRAMA_EDIT_ID}`,
    KATIA_CRONOGRAMA_EDIT: `/${INTERNAL_PATHS.PROGRAMACION_DEFAULT}/${INTERNAL_PATHS.PROGRAMACION_HOME}/${INTERNAL_PATHS.CRONOGRAMA_EDIT}`,

    KATIA_PLANEACION_LIST: `/${INTERNAL_PATHS.PROGRAMACION_DEFAULT}/${INTERNAL_PATHS.PROGRAMACION_HOME}/${INTERNAL_PATHS.PLANEACION_LIST}`,
    KATIA_PLANEACION_NEW: `/${INTERNAL_PATHS.PROGRAMACION_DEFAULT}/${INTERNAL_PATHS.PROGRAMACION_HOME}/${INTERNAL_PATHS.PLANEACION_NEW}`,
    KATIA_PLANEACION_EDIT_ID: `/${INTERNAL_PATHS.PROGRAMACION_DEFAULT}/${INTERNAL_PATHS.PROGRAMACION_HOME}/${INTERNAL_PATHS.PLANEACION_EDIT_ID}`,
    KATIA_PLANEACION_EDIT: `/${INTERNAL_PATHS.PROGRAMACION_DEFAULT}/${INTERNAL_PATHS.PROGRAMACION_HOME}/${INTERNAL_PATHS.PLANEACION_EDIT}`,

    /**
     * SERVER
     */
    SERVER_ERROR_401: `/${INTERNAL_PATHS.SERVER_ERROR_401}`,
    SERVER_ERROR_403: `/${INTERNAL_PATHS.SERVER_ERROR_403}`,
    SERVER_ERROR_404: `/${INTERNAL_PATHS.SERVER_ERROR_404}`,
};
