import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MODAL_CONSTANTS } from '@data/constants';

@Component({
  selector: 'app-image-modal',
  templateUrl: './image-modal.component.html',
  styleUrls: ['./image-modal.component.scss']
})
export class ImageModalComponent implements OnInit {
  public dataMsg = MODAL_CONSTANTS.IMAGE;


  constructor(
    public dialogo: MatDialogRef<ImageModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
    console.log(this.dataMsg);
  }

  /**
   * Cerrar el Modal
   */
  closeModal(): void {
    this.dialogo.close(false);
  }
  /**
   * Confirmacion del Modal
   */
  confirmModal(): void {
    this.dialogo.close(true);
  }

  ngOnInit(): void {
  }

}
